import React from "react"
import promo from "../../src/video/rf-dance-bachata.mp4"
import Hero from "../components/hero"
import ClassLinks from "../components/classLinks"
import Contact from "../components/contact"
import Layout from '../components/layout-default'
import '../styles/layout.scss'

import TeamImg from "../images/team.jpeg"

const BachataThursdays = () => (
  <Layout
    title="Bachata Classes in Orange County | RF Dance"
    description="OC's Homegrown latin dance classes with Rhythmic Fusion. $15 for a single class, or $65 for a 5 class bundle." 
    pathname="bachata-thursdays"
    img={TeamImg}>
    <Hero 
      titleRed="Bachata"
      titleWhite="Thursdays"
      subtitle="Join us free this week!"
      promo={promo} />
      
    <div className="content-block">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds display--inline-block has-text-centered">
            <h2>Bachata Thursdays are here!</h2>
            <p><strong>Monday nights have been a fun night and a huge success.</strong> To meet the demand, RF Dance is now adding an additional night during the week of bachata lessons.</p>
            <p>Our Bachata Thursday classes always begin with a fun, all-levels warm-up in our main studio room. After our muscles are warm and our mindset is hot, we separate into different studio rooms for beginner, intermediate, and advanced levels.</p>
            <p>After an hour of instruction, all levels gather back into our main studio room where our instructors will review all of the evening’s material and allow participants to film the newly learned bachata movements.</p>
            <p>Our classes end with more bachata music and open floor space where all participants may practice their new moves in a friendly and welcoming social environment. <strong>You don't want to miss Bachata Thursdays!</strong></p>
          </div>
          <div className="column display--inline-block">
            <div className="tile tile-custom is-secondary-custom">
              <p><strong>When:</strong> 8PM - 9:15PM</p>
              <p><strong>Where:</strong> <a href="https://goo.gl/maps/n5k6pfzF84x" rel="noopener noreferrer" target="_blank" >RF Dance Studio<br />3625 W MacArthur Blvd. #308<br />Santa Ana, CA 92701</a><br/>(949) 689-1835</p>
              <p><strong>Free this week!</strong></p>
              <p><strong>$15</strong> Single Drop-in Class<br /><strong>$65</strong> 5-Class Bundle (save 13%)<br /><strong>$75</strong> Monthly Unlimited (includes all Salsa Wednesdays and Bachata Thursdays classes)</p>
            </div>
            <ClassLinks />
          </div>
        </div>
      </div>
    </div>
    <Contact />
  </Layout>
)

export default BachataThursdays