import React from "react"
import contactStyles from "./contact.module.scss"

export default props => (
  <div id="contact">
    <div className={`${contactStyles.hero} ${contactStyles.heroSecondary}`}>
      <div className="container">
        <div className={contactStyles.heroContent}>
          <h2 className={`${contactStyles.title} title`}>{props.title || "Interested? Find a class online."}</h2>
        </div>
        <div className={contactStyles.heroContent}>
          <a href={props.href || "/classes/"} className="button is-rounded is-info">{props.anchor || "See classes"}</a>
        </div>
      </div>
    </div>
  </div>
)